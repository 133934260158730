import email from '../assets/email.png';
import mobile from '../assets/mobile.png';

import technology from '../assets/data-management.png'
import ideation from '../assets/lightbulb.png'
import test from '../assets/test.png'

import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.svg';

export default {
  email,
  mobile,
  technology,
  ideation,
  test,
  profile,
  circle,
  logo,
};